import { cn } from '#app/utils/misc'

const weightClasses = {
	light: 'font-light',
	regular: 'font-normal',
	medium: 'font-medium',
	semibold: 'font-semibold',
	bold: 'font-bold',
} as const

const textColorClasses = {
	white: 'text-white',
	black: 'text-black-90',
} as const

type FontWeight = keyof typeof weightClasses
type TextColor = keyof typeof textColorClasses

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
	as: string
	heading?: string
	weight?: FontWeight
	textColor?: TextColor
}

const Heading = ({
	as = 'h1',
	heading = '',
	weight = 'regular',
	textColor = 'black',
	className,
	children,
	...rest
}: HeadingProps) => {
	const fontWeightClass = weightClasses[weight] || weightClasses.regular
	const textColorClass = textColorClasses[textColor] || textColorClasses.black

	switch (as) {
		case 'h0':
			return (
				<h1
					className={cn(
						`text-[64px] leading-normal tracking-[-2.24px] text-black-90 md:text-[104px] md:tracking-[-3.64px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : heading}
				</h1>
			)
		case 'h1':
			return (
				<h1
					className={cn(
						`text-shadow md:tracking-[-2.52px ] text-[56px] leading-normal tracking-[-1.96px] text-black-90 md:text-[72px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : heading}
				</h1>
			)
		case 'h2':
			return (
				<h2
					className={cn(
						`text-shadow text-[48px] leading-normal tracking-[-1.68px] text-black-90 md:text-[64px] md:tracking-[-2.24px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
				>
					{children ? children : heading}
				</h2>
			)
		case 'h3':
			return (
				<h3
					className={cn(
						`text-shadow text-[40px] leading-normal tracking-[-1.4px] text-black-90 md:text-[56px] md:tracking-[-1.96px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : heading}
				</h3>
			)
		case 'h4':
			return (
				<h4
					className={cn(
						`text-shadow text-[32px] leading-normal tracking-[-1.12px] text-black-90 md:text-[48px] md:tracking-[-1.68px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : heading}
				</h4>
			)
		case 'h5':
			return (
				<h5
					className={cn(
						`text-shadow text-[24px] leading-normal tracking-[-0.84px] text-black-90 md:text-[40px] md:tracking-[-1.4px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : heading}
				</h5>
			)
		case 'h6':
			return (
				<h6
					className={cn(
						`text-shadow tracking-[-0.84px]text-black-90 text-[24px] leading-normal md:text-[32px] md:tracking-[-1.12px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : heading}
				</h6>
			)
		case 'h7':
			return (
				<h6
					className={cn(
						`text-shadow text-[18px] leading-normal tracking-[-0.63px] text-black-90 md:text-[24px] md:tracking-[-0.84px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : heading}
				</h6>
			)
		case 'h8':
			return (
				<h6
					className={cn(
						` text-[16px] leading-normal tracking-[-0.56px] text-black-90 md:text-[20px] md:tracking-[-0.70px]   ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : heading}
				</h6>
			)
	}
}

export default Heading
