import { cn } from '#app/utils/misc'

const weightClasses = {
	light: 'font-light',
	regular: 'font-normal',
	medium: 'font-medium',
	semibold: 'font-semibold',
	bold: 'font-bold',
} as const

export const textColorClasses = {
	white: 'text-white',
	black: 'text-black-90',
} as const

type FontWeight = keyof typeof weightClasses
export type TextColor = keyof typeof textColorClasses | string

interface BodyProps extends React.HTMLAttributes<HTMLParagraphElement> {
	as?: 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs' | 'span'
	body?: string | number | undefined
	weight?: FontWeight
	textColor?: TextColor
}

const Body = ({
	as = 'm',
	body = '',
	weight = 'regular',
	textColor = 'black',
	className,
	children,
	...rest
}: BodyProps) => {
	const fontWeightClass = weightClasses[weight] || weightClasses.regular
	const textColorClass = (textColorClasses as any)[textColor] || textColor
	switch (as) {
		case 'xl':
			return (
				<p
					className={cn(
						`text-[20px] leading-7 md:text-xl ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : body}
				</p>
			)
		case 'l':
			return (
				<p
					className={cn(
						`text-[18px] leading-[1.375rem] tracking-[-0.27px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : body}
				</p>
			)
		case 'm':
			return (
				<p
					className={cn(
						`m-0 text-[16px] leading-[1.375rem] tracking-[-0.24px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : body}
				</p>
			)
		case 's':
			return (
				<p
					className={cn(
						`text-[14px] leading-[1.375rem] tracking-[-0.21px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : body}
				</p>
			)
		case 'xs':
			return (
				<p
					className={cn(
						`text-[12px] leading-[1.375rem] tracking-[-0.18px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : body}
				</p>
			)
		case 'xxs':
			return (
				<p
					className={cn(
						`text-[8px] leading-[1.375rem] tracking-[-0.18px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : body}
				</p>
			)
		case 'span':
			return (
				<span
					className={cn(
						`text-[12px] leading-[0.5rem] tracking-[-0.18px] ${fontWeightClass} ${textColorClass}`,
						className,
					)}
					{...rest}
				>
					{children ? children : body}
				</span>
			)
	}
}

export default Body
